import {DPHierarchy, DPMenuTerm, DPParentTerm} from 'types/DPTypes'

import {Icon} from 'assets/icon'
import {Theme} from 'layout/theme'
import React, {Dispatch, SetStateAction} from 'react'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import css from './headernav.module.scss'

const styles = fromModule(css)

export const HeaderNav: React.FC<{
	items: DPHierarchy<DPMenuTerm>
	setHoveringItem: (item: DPMenuTerm) => void
	subsite?: boolean
}> = ({items, setHoveringItem, subsite}) => {
	if (items?.length === 0) return null

	return (
		<nav className={styles.headernav.mod({subsite})()}>
			{items
				.filter((item) => !!item)
				.map((item, i) => (
					<div
						key={i}
						className={styles.headernav.item()}
						onFocus={() => setHoveringItem(item)}
						onMouseOver={() => setHoveringItem(item)}
					>
						<Link
							href={item.link?.url}
							className={styles.headernav.item.link()}
						>
							<span className={styles.headernav.item.link.text()}>
								{item.name}
							</span>
							{item.children?.length > 0 && (
								<span className={styles.headernav.item.link.icon()}>
									<Icon icon="ChevronDown" />
								</span>
							)}
						</Link>
					</div>
				))}
		</nav>
	)
}

export const HeaderNavDropdown: React.FC<{
	item: DPParentTerm<DPMenuTerm>
	isOpen: boolean
	setHoveringItem?: Dispatch<SetStateAction<any>>
}> = ({item, isOpen, setHoveringItem}) => {
	if (!item?.children || item?.children.length === 0) return null

	return (
		<div className={styles.dropdown.is({open: isOpen})()} id={item.name}>
			<Theme.OuterContainer>
				<Theme.Container>
					<div className={styles.dropdown.items()}>
						{item.children.map((child, i) => (
							<div className={styles.dropdown.items.item()} key={i}>
								<div className={styles.dropdown.item()}>
									<Link
										href={child.link?.url}
										className={styles.dropdown.item.link()}
										onBlur={() => setHoveringItem(null)}
										onFocus={() => setHoveringItem(item)}
									>
										<span className={styles.dropdown.item.link.text()}>
											{child.name}
										</span>
										<span className={styles.dropdown.item.link.icon()}>
											<Icon icon="ArrowRight" />
										</span>
									</Link>
									{child && child.description && (
										<div className={styles.dropdown.item.description()}>
											{child.description}
										</div>
									)}
								</div>
							</div>
						))}
					</div>
				</Theme.Container>
			</Theme.OuterContainer>
		</div>
	)
}
