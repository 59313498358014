'use client'

import {Footer} from 'layout/partials/footer/footer'
import {Header} from './partials/header/header'
import React from 'react'
import {Theme} from 'layout/theme'
import css from './layout.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'

const styles = fromModule(css)

export const Layout: React.FC<{children: React.ReactNode}> = ({children}) => {
	const {general} = useApp()

	return (
		<div className={styles.layout()}>
			<Header items={general?.menu} />
			<span id="scroll" />
			<Theme.OuterContainer>
				<div className={styles.layout.content()}>{children}</div>
			</Theme.OuterContainer>
			<Footer {...general?.footer} />
		</div>
	)
}
