'use client'

import React, {Fragment, useEffect, useState} from 'react'
import {DPHierarchy, DPMenuTerm} from 'types/DPTypes'
import {HeaderNav, HeaderNavDropdown} from './headernav'

import {Icon} from 'assets/icon'
import {Theme} from 'layout/theme'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import {usePathname} from 'next/navigation'
import {useTranslation} from 'util/i18'
import {Link} from 'util/link'
import {useLinks} from 'util/links'
import {fromModule} from 'util/styler/Styler'
import css from './header.module.scss'
import {HeaderMobilenav} from './headermobilenav'

const Dialog = dynamic(() => import('@material-ui/core/Dialog'), {ssr: false})
const HeaderSearchInput = dynamic(
	() => import('./headersearchinput').then((mod) => mod.HeaderSearchInput),
	{ssr: false}
)

export type HeaderLinkType = {
	title: string
	url: string
	id: string
	children: Array<{
		title: string
		url: string
		description: string
	}>
}

const styles = fromModule(css)

export const Header: React.FC<{
	items: DPHierarchy<DPMenuTerm>
}> = ({items}) => {
	const [searchisOpen, setSearchOpen] = useState<boolean>(false)
	const [hoveringID, setHoveringID] = useState<number>(null)
	const hoveringItem = items.find((item) => item.id === hoveringID)
	const setHoveringItem = (item) => setHoveringID(item ? item.id : null)

	const t = useTranslation()
	const {home, contact} = useLinks()
	const envProjectKey = process.env.NEXT_PUBLIC_PROJECT_KEY as
		| 'hyperspectral'
		| 'iclink'
		| 'nanoic'
	const subsite =
		envProjectKey === 'nanoic' ||
		envProjectKey === 'hyperspectral' ||
		envProjectKey === 'iclink'

	return (
		<header
			className={styles.header()}
			onMouseLeave={() => setHoveringItem(null)}
		>
			<div className={styles.header.rowcontainer()}>
				<Theme.OuterContainer>
					<div className={styles.header.row()}>
						<div className={styles.header.row.left.mod({subsite})()}>
							{!subsite && <LogoImec url={home?.url} />}
							{envProjectKey === 'hyperspectral' && (
								<LogoHome url={home?.url} />
							)}
							{envProjectKey === 'nanoic' && <LogoNanoIc url={home?.url} />}
							{envProjectKey === 'iclink' && <LogoIclink url={home?.url} />}
							<HeaderNav
								items={items}
								setHoveringItem={setHoveringItem}
								subsite={subsite}
							/>
						</div>
						<div className={styles.header.row.right()}>
							{!subsite && !searchisOpen && (
								<Link
									href={contact.url}
									className={styles.header.row.right.link()}
								>
									<span className={styles.header.row.right.link.text()}>
										{contact.title}
									</span>
								</Link>
							)}
							{!subsite && (
								<HeaderSearchAlgolia
									searchisOpen={searchisOpen}
									onSearchClick={(searchisOpen) => setSearchOpen(searchisOpen)}
								/>
							)}
							{/* {subsite && (
								<div className={styles.header.row.right.partof()}>
									<Trusted className={styles.header.row.right.partof.label()}>
										{t.footer[envProjectKey]}
									</Trusted>
									<LogoImec />
								</div>
							)} */}
							{subsite && (
								<Theme.Button
									href={contact?.url}
									className={styles.header.button()}
									mod="rounded"
								>
									<Theme.ButtonText>{t.header.request}</Theme.ButtonText>
									<Theme.ButtonIcon />
								</Theme.Button>
							)}
							<HeaderHamburger items={items} subsite={subsite} />
						</div>
					</div>
				</Theme.OuterContainer>
			</div>
			{items.map((item) => (
				<div
					key={item.id}
					className={styles.header.dropdowncontainer.is({
						open: item === hoveringItem
					})()}
					onMouseOver={() => setHoveringItem(item)}
					onMouseLeave={() => setHoveringItem(null)}
				>
					<HeaderNavDropdown
						item={item}
						isOpen={item === hoveringItem}
						setHoveringItem={setHoveringItem}
					/>
				</div>
			))}
		</header>
	)
}

const LogoHome: React.FC<{url?: string}> = ({url}) => {
	return (
		<Link
			href={url || '/'}
			title="Home"
			aria-label="Home"
			className={styles.header_home()}
		>
			<Icon icon="Home" />
		</Link>
	)
}

const LogoIclink: React.FC<{url?: string}> = ({url}) => {
	return (
		<Link
			href={url || 'https://www.imeciclink.com/en'}
			title="imec.IC-link"
			aria-label="imec.IC-link"
			className={styles.header_logo()}
		>
			<Image
				src="/assets/img/imec-iclink-logo.svg"
				width={332}
				height={80}
				alt="imec.IC-link logo"
				className={styles.header_logo.img.mod('iclink')()}
			/>
		</Link>
	)
}

const LogoNanoIc: React.FC<{url?: string}> = ({url}) => {
	return (
		<Link
			href={url || 'https://www.nanoic-project.eu/en'}
			title="NanoIC-project"
			aria-label="NanoIC-project"
			className={styles.header_logo()}
		>
			<Image
				src="/assets/img/NanoIC_logo_RGB_horizontal.svg"
				width={4604}
				height={1360}
				alt="NanoIC-project logo"
				className={styles.header_logo.img.mod('nanoic')()}
			/>
		</Link>
	)
}

const LogoImec: React.FC<{url?: string}> = ({url}) => {
	const imecbe = process.env.NEXT_PUBLIC_PROJECT_KEY === 'vlaanderen'

	return (
		<Link
			href={url || 'https://www.imec-int.com/en'}
			title="imec"
			aria-label="imec"
			className={styles.header_logo()}
		>
			{/* <Image
				src="/assets/img/imec-logo.svg"
				width={373}
				height={112.4}
				alt="imec logo"
				className={styles.header_logo.img()}
			/> */}
			{imecbe ? (
				<Image
					src="/assets/img/imec-logo-40years-nl.svg"
					width={567.44}
					height={347}
					alt="imec logo"
					className={styles.header_logo.img()}
				/>
			) : (
				<Image
					src="/assets/img/imec-logo-40years.svg"
					width={562.44}
					height={325.16}
					alt="imec logo"
					className={styles.header_logo.img()}
				/>
			)}
		</Link>
	)
}

export const HeaderSearchAlgolia: React.FC<{
	searchisOpen: boolean
	onSearchClick: (searchisOpen: boolean) => void
}> = ({searchisOpen, onSearchClick}) => {
	const t = useTranslation()

	return (
		<div className={styles.header_search()}>
			{!searchisOpen && (
				<button
					title="Search"
					aria-label={t.header.search.toggle_arialabel}
					aria-hidden={searchisOpen ? true : false}
					onClick={() => onSearchClick(true)}
					className={styles.header_search.button()}
				>
					<Icon icon="Search" />
				</button>
			)}
			{searchisOpen && <HeaderSearchInput />}
		</div>
	)
}

export const HeaderHamburger: React.FC<{
	items: DPHierarchy<DPMenuTerm>
	subsite?: boolean
}> = ({items, subsite}) => {
	const [isOpenMenu, setMenu] = useState(false)
	const pathname = usePathname()
	const t = useTranslation()

	useEffect(() => {
		setMenu(false)
	}, [pathname])

	return (
		<Fragment>
			<a
				href="#"
				className={styles.header_hamburger.mod({subsite})()}
				onClick={() => setMenu(!isOpenMenu)}
				aria-expanded={isOpenMenu ? true : false}
				aria-label={t.header.hamburger.open_arialabel}
			>
				<span className={styles.header_hamburger.bar()} />
				<span className={styles.header_hamburger.bar()} />
				<span className={styles.header_hamburger.bar()} />
			</a>
			<Dialog
				open={isOpenMenu}
				onClose={() => setMenu(!isOpenMenu)}
				aria-hidden={isOpenMenu ? false : true}
			>
				<HeaderMobilenav items={items} onCloseMenu={() => setMenu(false)} />
			</Dialog>
		</Fragment>
	)
}
